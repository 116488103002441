#layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#layout #layout-columns {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 100;
}

#layout main,
#layout #left-sidebar {
    height: calc(100vh - 100px);
}

#layout main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    padding: 0 50px;
}
