.home-container {
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 0 40px;
}

.home-container .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-container .options-header .bzn-pickers {
    display: flex;
}

.home-container .options-header .bzn-pickers > * {
    margin: 0 5px;
}

.home-container .visualisation-content {
    display: grid;
    grid-template-columns: 0fr 4fr 1fr;
}

.home-container .visualisation-content .visualisation-options {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.home-container .visualisation-content .visualisation-options > div {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 12px 0;
}

.home-container .visualisation-content .visualisation-options .view-options .p-inputswitch-slider {
    background: #77ce3e !important;
    box-shadow: none !important;
}

.home-container .visualisation-content .visualisation-options .view-options span.disabled {
    color: rgb(143, 143, 143);
}

.visualisation-options-container {
    width: fit-content;
    margin-left: auto;
}

.bzn-pickers .p-inputwrapper {
    width: 180px;
}

.countries-filter {
    margin: 20px 0 !important;
}

.countries-filter .p-inputwrapper {
    width: 260px;
}

@media only screen and (max-width: 1700px) {
    .home-container {
        padding: 0;
    }
}
