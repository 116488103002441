.menu-item,
#avatar-select {
    display: flex;
    flex-flow: row nowrap;
    max-width: fit-content;
    align-items: center;
    margin: 0;
}

.menu-item #avatar-select-dropdown {
    border: none;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    width: 0px;
    align-items: stretch;
}

.menu-item > #avatar-select-dropdown span.p-inputtext {
    box-shadow: none;
}

.menu-item > #avatar-select-dropdown .p-dropdown-label.p-inputtext.p-placeholder {
    padding: 0;
    margin: 0;
}

.menu-item > #avatar-select-dropdown .p-dropdown-label.p-inputtext.p-dropdown-label-empty {
    padding: 0;
    margin: 0;
}

.menu-item > #avatar-select-dropdown .p-dropdown-trigger {
    max-width: fit-content;
}

header > .p-menubar .menu-item span.name {
    white-space: pre;
}

.avatar-dropdown-element a {
    color: inherit;
    text-decoration: none;
}
