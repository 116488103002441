.rv-hint {
    width: fit-content;
    padding: 16px 40px;
    background-color: #495057;
    border-radius: 8px;
    border: 1px solid white;
    color: white;
    bottom: 0 !important;
    right: 0 !important;
    left: unset !important;
}

.plot {
    position: relative;
}

.rv-hint > div {
    margin: 6px 0;
}

.rv-hint div.title {
    text-align: center;
    margin-bottom: 8px;
}

.rv-hint:last-child {
    right: unset !important;
}

.rv-hint .more-info-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.rv-hint {
    pointer-events: all !important;
}

.rv-hint:nth-child(2) {
    position: fixed !important;
    bottom: 40px !important;
    right: 80px !important;
}

.plot-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.plot-wrapper .net-position-wrapper {
    padding: 20px;
    background-color: #495057;
    border-radius: 8px;
    border: 1px solid white;
    color: white;
}

.plot-wrapper .net-position-wrapper .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plot-wrapper .net-position-wrapper .title i {
    margin-left: 10px;
    font-size: 0.75rem;
    border-radius: 50%;
    padding: 5px;
    border: 1px white solid;
    cursor: pointer;
}

.plot-wrapper .net-position-wrapper.closeable {
    transition: max-height 0.375s ease-in-out, padding 0.375s ease-in-out;
    overflow: hidden;
    padding: 0 20px;
}

.plot-wrapper .net-position-wrapper.closeable.open {
    max-height: 150px;
    padding: 20px;
}

.plot-wrapper .net-position-wrapper.closeable.closed {
    max-height: 0;
}

.more-info-content .p-dialog-content {
    padding: 1.5rem !important;
}

.polygon {
    z-index: 99;
}

.data-source a {
    color: black;
}

.vertex-buttons {
    display: flex;
    margin: 2px -4px;
    justify-content: center;
}

.vertex-buttons > * {
    margin: 0 4px !important;
}

.plot-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 30px;
    position: relative;
}

.plot-container .info-message {
    margin-bottom: 10px;
}

.zoom-container {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 100;
    font-size: medium;
    font-weight: bold;
    width: 220px;
    text-align: center;
}

.zoom-container .zoom-slider {
    margin-top: 10px;
}

.close-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 !important;
}

i.close-hint-icon {
    position: absolute;
    cursor: pointer;
    right: 6px;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid transparent;
}

i.close-hint-icon:hover {
    border: 1px solid white;
}
