.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #fff;
}
.login-form-container {
    flex: 4;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px 40px;
    width: 400px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border: 1px solid lightgray;
}
.center-login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 650px;
    width: 80vw;
    max-width: 500px;
}
.center-login-container.register {
    height: 780px;
}
.login-form-container label {
    margin-left: auto;
}
.login-form-container hr {
    width: 100%;
    border-top: 1px solid #cbd1d8;
    border-bottom: none;
    box-sizing: border-box;
}
.login-form-container button {
    width: 100%;
}
#login-error-message {
    text-align: center;
    font-weight: bold;
    height: 20px;
    margin: 10px 0 !important;
    color: #c0392b !important;
}
#login-locked-message {
    margin-top: 20px;
    text-align: center;
    color: black !important;
}
.login-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
.left-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.upper-container,
.lower-container {
    flex: 1;
    background: #272f37;
}
.upper-container {
    border-top-left-radius: 10px;
    background: #2b405e;
}
.lower-container {
    border-bottom-left-radius: 10px;
    background: #2e4672;
}
.left-container > svg {
    position: absolute;
    width: 150px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
}
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.login-wrapper > svg {
    width: 350px;
}
.language-select-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}
#cursor {
    cursor: pointer;
}
#centered-text {
    text-align: center !important;
}

#login-logo {
    width: 100%;
    height: auto;
}

#login-logo-container {
    width: 55%;
    margin: 30px 0;
}

.login-container .forgot-pass {
    text-decoration: none;
    align-self: flex-end;
    color: black;
}

.login-container .forgot-pass i {
    margin-right: 5px;
}

.sign-up {
    background: #69bf30 !important;
}

.sign-up span {
    text-decoration: none;
}

span.contact-us a {
    color: inherit !important;
}

/*responsive part */

@media only screen and (max-width: 635px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #fff;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .center-login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 500px;
        width: 100%;
        max-width: 340px;
        margin-bottom: 10px;
    }

    .left-container {
        flex: 3;
        display: flex;
        flex-direction: row;
        height: 100%;
        max-height: 420px !important;
        position: relative;
    }

    .login-form-container {
        flex: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        background-color: #f0f1f2 !important;
        padding: 30px 20px;
        width: 340px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0 !important;
    }

    .lower-container {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0 !important;
        background: #2e4672;
    }

    .upper-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .lower-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
