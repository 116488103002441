header {
    width: 100%;
    position: fixed;
    z-index: 1000;
    height: 66px;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid #e5e7eb;
}

header > .p-menubar {
    border-radius: 0px;
    padding: 12px 5% 12px 15px;
    border: none;
    color: black;
    max-width: 1600px;
    width: 100%;
    height: inherit;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    left: calc(50% - 10px);
    transform: translateX(-50%);
}

header .main-logo {
    height: 30px;
    width: auto;
}

header #main-logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: calc((100vw - 1750px) / 4);
}

header > .p-menubar #logout {
    cursor: pointer;
}

header > .p-menubar .p-menubar-end {
    display: flex;
    align-items: center;
}

header > .p-menubar .p-menubar-end #header-right {
    display: flex;
}

header > .p-menubar .p-menubar-start {
    display: none;
}

header > .p-menubar .menu-item {
    margin: 0 10px;
}

header > .p-menubar .mobile-only {
    display: none;
}

header > .p-menubar .menu-item > .p-avatar,
header > .p-menubar .menu-item > .pi {
    margin: 0 5px;
}

header > .p-menubar .p-input-icon-left {
    margin: 0 10px;
}

header > .p-menubar #lefticon {
    background-color: transparent;
    box-shadow: none;
}

header > .p-menubar .menu-item > .pi-bell {
    font-size: 2em;
}

/* Basic design 1 */

header > .p-menubar .p-menubar-root-list > .p-menuitem span {
    color: black !important;
}

header {
    position: relative;
}

header > span.title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    z-index: 1;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    pointer-events: none;
}

header > .p-menubar > .p-menubar-end span > * {
    color: black !important;
}

header .clickable {
    cursor: pointer;
}

.p-overlaypanel .notification-item {
    cursor: pointer;
    padding: 4px 2px;
}

.p-overlaypanel .notification-item:hover {
    background: lightgray;
}

.electricity-header {
    display: flex;
}

.electricity-header > a > img {
    width: 100px;
    height: auto;
    object-fit: contain;
    margin-left: 30px;
}

@media only screen and (max-width: 1850px) {
    header > .p-menubar {
        position: static;
        transform: none;
        padding: 12px 5% 12px 15px;
        max-width: none;
    }

    header > .p-menubar .p-menubar-start {
        display: block;
    }

    header #main-logo {
        display: none;
    }
}

@media only screen and (max-width: 1580px) {
    header > .p-menubar .p-menuitem > .p-menuitem-link {
        padding: 12px 8px !important;
    }
}

@media only screen and (max-width: 960px) {
    header > .p-menubar .p-menubar-end {
        display: none;
    }

    header > .p-menubar .p-menubar-button {
        margin-left: auto;
    }

    header > .p-menubar .mobile-only {
        display: block;
    }

    header > .p-menubar > .p-menubar-button > i {
        color: black !important;
    }

    header > .p-menubar > .p-menubar-button:hover {
        color: black !important;
    }
}
