.users-dashboard-container h1 {
    color: var(--eagle-green);
    text-align: center;
}

.users-dashboard-container .users-dashboard-table {
    border-collapse: collapse;
}

.users-dashboard-container .users-dashboard-table thead {
    color: var(--eagle-green);
}

.users-dashboard-container .users-dashboard-table td,
.users-dashboard-container .users-dashboard-table th {
    padding: 8px 32px;
}

.users-dashboard-container .users-dashboard-table tr {
    border-bottom: 1px solid var(--eagle-grey-border);
}

.users-dashboard-container .users-dashboard-table td.centered {
    text-align: center;
}
