.hour-picker {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    margin: 10px;
    cursor: pointer;
    border-collapse: collapse;
}

.hour-picker .hour-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 12px;
    align-items: center;
    border: 1px solid black;
    font-weight: bold;
}

.hour-picker .hour-tab.selected {
    background-color: #77ce3e;
    color: white;
}

.hour-picker .hour-tab:not(:first-child) {
    border-left: none;
}

@media only screen and (max-width: 1700px) {
    .hour-picker {
        grid-template-columns: repeat(12, 1fr);
    }

    /* On smaller screens hour picker is in two lines so first element of the second line
    should still have the border */
    .hour-picker .hour-tab:nth-child(13) {
        border-left: 1px solid black;
    }
}
