footer {
    background-color: #e9e9ea;
    height: 33px;
}
footer > div {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #424444;
    color: white;
}

footer > div span {
    font-size: small;
    margin: 0 20px;
}
